import { createContext, useContext } from 'react'
import { AppProviderInterface } from '@shared/interfaces/app-provider.interface'
import { ContentLayout } from '@shared/enums/content/content-layout.enum'

export const AppContext = createContext<AppProviderInterface>({
  searchQuery: '',
  category: '',
  referer: '',
  handleOnSearch: () => '',
  handleCategory: () => '',
  handleReferer: () => '',
  currentPath: '',
  handleCurrentPath: () => '',
  isLoading: false,
  toggleIsLoading: () => false,
  hasWhiteLabel: false,
  logoToUse: '',
  buttonColour: '',
  fontColour: '',
  handleAddToCart: () => '',
  playerSize: 0,
  setPlayerSize: () => ({ width: 0, height: 0 }),
  contentLayoutStyle: ContentLayout.LEGACY,
  isModernLayout: false,
  isMobileStyle: false,
})

// Allow to components access to the context provider
export const useAppProvider = () => useContext(AppContext)
