import styles from './styles.module.scss'
import { VidzingLogo } from '@library/_images/vidzing-logo'
import * as constants from '@components/layout/footer/_constants'
import NavigationMenu from '@ui/navigation'
import NavigationSocial from '@ui/navigation/social'
import SignUpHome from '@features/home-page/sign-up'
import * as texts from '@features/home-page/_texts'
import CopyRight from '@library/footer/copyRight'
import { FooterVariantEnum } from '@shared/enums/layout/footer.enum'
import RenderIf from '@utils/render-if'
import * as homePageConstants from '@features/home-page/_constants'
import { ReactElement } from 'react'
import { clsx } from 'clsx'

export interface Props {
  variant?: FooterVariantEnum
  channelPathname?: string
  renderLogo?: boolean
}

export default function Footer({
  variant = FooterVariantEnum.NORMAL,
  channelPathname,
  renderLogo = true,
}: Props): ReactElement {
  const isInChannel = !!channelPathname

  return (
    <footer
      className={clsx(styles.footer, isInChannel ? styles.inChannel : styles.regular, {
        [styles.contentV2]: variant === FooterVariantEnum.CONTENT_V2,
      })}
    >
      <RenderIf isTrue={variant === FooterVariantEnum.NORMAL}>
        <SignUpHome
          title={texts.SIGN_UP_TITLE}
          subtitle={texts.SIGN_UP_SUBTITLE}
          ctaOne={homePageConstants.GET_STARTED_CTA}
        />
      </RenderIf>
      <div className={styles.wrapper}>
        <div className={styles.logoWrapper}>
          <RenderIf isTrue={renderLogo}>
            <VidzingLogo className={styles.logo} />
          </RenderIf>
        </div>
        <div className={styles.navWrapper}>
          <NavigationMenu menus={constants.FOOTER_MENU_NAVIGATION}>
            <NavigationSocial
              title={constants.SOCIAL_NAVIGATION.title}
              menu={constants.SOCIAL_NAVIGATION.menu}
            />
          </NavigationMenu>
        </div>
      </div>
      <CopyRight />
    </footer>
  )
}
