import { useEffect, useState } from 'react'
import useDebounce from '@hooks/use-debounce'
import { delay } from '@utils/_animations/delay'
import { ONE_SECOND_MS } from '@root/constants'

interface Size {
  width: number | undefined
  height: number | undefined
}

export default function UseWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  })

  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  const debounced = useDebounce(100, handleResize)

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    const setInitialWindowSize = async () => {
      await delay(ONE_SECOND_MS)
      debounced()
    }

    setInitialWindowSize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}
