import { useMemo } from 'react'
import useWindowSize from 'hooks/use-window-size'
import { TABLET_BREAKPOINT } from '@shared/constants/layout/constants'

export default function usePageWidth(targetSize = TABLET_BREAKPOINT) {
  const windowsSize = useWindowSize()

  return useMemo(() => {
    return windowsSize.width !== undefined && windowsSize.width < targetSize
  }, [targetSize, windowsSize.width])
}
