import style from './styles.module.scss'
import useRealVh from './_hooks/use-real-vh'
import type { CSSProperties, ReactElement, ReactNode } from 'react'

export type Props = {
  children: ReactNode
  className?: string
  variant?:
    | 'absoluteHeader'
    | 'fixedHeader'
    | 'staticHeader'
    | 'stickyHeaderWithHeadImage'
    | 'stickyHeaderWithoutHeadImage'
    | 'vidzingBackground'
    | 'vidzingNight'
    | 'whiteLabel'
    | 'vidzing2024Theme'
    | 'absoluteHeaderWithPaddingTopMobile'
  styles?: CSSProperties
}

export default function PageFrame({
  className,
  children,
  variant = 'staticHeader',
  styles,
}: Props): ReactElement {
  useRealVh()

  return (
    <div style={styles ?? {}} className={[style.wrapper, style[variant], className].join(' ')}>
      {children}
    </div>
  )
}
